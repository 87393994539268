<template>
  <v-container>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3"
        ><v-icon color="primary" left>mdi-bell</v-icon>Notificações
      </v-card-title>

      <v-divider></v-divider>
      <v-tabs fixed-tabs v-model="tab">
        <v-tab>Novas</v-tab>
        <v-tab>Lidas</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <NovasNotificacoes
            :perfil_id="perfil_id"
            :user_id="user_id"
            :key="tab"
          />
        </v-tab-item>
        <v-tab-item>
          <LidasNotificacoes
            :perfil_id="perfil_id"
            :user_id="user_id"
            :key="tab"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Notificacao",
  props: {},

  data() {
    return {
      tab: 0,
      loading: true,
      text: "Notificações",
      notificacao: "",
      notificacoes: [],
    };
  },
  components: {
    NovasNotificacoes: () => import("./components/NovasNotificacoes.vue"),
    LidasNotificacoes: () => import("./components/LidasNotificacoes.vue"),
  },
  watch: {},
  computed: {
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
  },
  methods: {},
};
</script>

<style></style>
